import dayjs from 'dayjs'
import { StrictEffect } from 'redux-saga/effects'
import { all, call, put, select, takeLatest } from 'typed-redux-saga'

import { ProfileStatus, ProfileType, afterSendingStatuses } from '@dltru/dfa-models'
import { errorTranslates, getProfileRequestType, openMessage } from '@dltru/dfa-ui'

import { isAxiosError } from '@utils/typeGuard'

import { sendAgreements } from '@store/auth'
import { authSelector } from '@store/auth/selectors'
import { profileDetailsSlice } from '@store/profiles/details'

import api from '@services/api'

import { roleFormsSlice } from './index'
import { roleFormSelector } from './selectors'

function catchProfileError(error: unknown) {
    const errorTranslate = isAxiosError(error)
        ? errorTranslates[error.response.data.error.code]
        : ''
    openMessage({
        type: 'error',
        message: `Возникли ошибки при сохранении анкеты${
            errorTranslate ? `: ${errorTranslate}` : ''
        }`,
    })
}

function* sendIndividualAnketa() {
    try {
        yield* put(roleFormsSlice.actions.setIsLoading(true))
        const uuid = yield* select(authSelector.selectUserUid)
        const agent = yield* select(roleFormSelector.selectAgentAnketa)
        const profile = yield* select(roleFormSelector.selectIndividualAnketa)
        const beneficialOwners = yield* select(roleFormSelector.selectBeneficialOwners)

        if (profile && uuid) {
            const { error } = yield* call(api.lib.storeIndividualForm, {
                ...profile,
                user_uuid: uuid,
                beneficiaries: beneficialOwners ? beneficialOwners : undefined,
                agent_uuids: agent?.id ? [`${agent.id}`] : undefined,
            })

            if (error) {
                throw error
            }

            yield* put(roleFormsSlice.actions.getProfiles())
        }
    } catch (error) {
        yield* call(catchProfileError, error)
    } finally {
        yield* put(roleFormsSlice.actions.setIsLoading(false))
    }
}

function* sendAgentAnketa() {
    try {
        const uuid = yield* select(authSelector.selectUserUid)
        const profile = yield* select(roleFormSelector.selectAgentAnketa)
        const { error, data } = yield* call(api.lib.storeAgentProfileService, {
            ...profile,
            user_uuid: uuid,
        })

        if (error) {
            throw error
        }

        yield* put(
            roleFormsSlice.actions.updateAgentAnketa({
                ...profile,
                id: data.item,
                uuid: data.item,
            }),
        )
    } catch (error) {
        yield* put(roleFormsSlice.actions.setError(error as string))
    } finally {
        yield* put(roleFormsSlice.actions.setIsLoading(false))
    }
}

function* sendLegalEntityAnketa() {
    try {
        const uuid = yield* select(authSelector.selectUserUid)
        const agent = yield* select(roleFormSelector.selectAgentAnketa)
        const profile = yield* select(roleFormSelector.selectLegalEntityAnketa)
        const beneficialOwners = yield* select(roleFormSelector.selectBeneficialOwners)

        if (profile && agent?.id && uuid) {
            const { error } = yield* call(api.lib.storeLegalEntityService, {
                ...profile,
                user_uuid: uuid,
                agent_uuid: agent.uuid,
                beneficial_owners_uuids: beneficialOwners ? beneficialOwners : undefined,
            })

            if (error) {
                throw error
            }

            yield* put(roleFormsSlice.actions.getProfiles())
        }
    } catch (error) {
        yield* call(catchProfileError, error)
    } finally {
        yield* put(roleFormsSlice.actions.setIsLoading(false))
    }
}

function* sendBusinessmanAnketa() {
    try {
        const uuid = yield* select(authSelector.selectUserUid)
        const agent = yield* select(roleFormSelector.selectAgentAnketa)
        const profile = yield* select(roleFormSelector.selectBusinessmanAnketa)
        const beneficialOwners = yield* select(roleFormSelector.selectBeneficialOwners)

        if (profile && uuid) {
            const { error } = yield* call(api.lib.storeBusinessmanService, {
                ...profile,
                user_uuid: uuid,
                agent_uuid: agent?.uuid,
                beneficial_owners_uuids: beneficialOwners ? beneficialOwners : undefined,
            })

            if (error) {
                throw error
            }

            yield* put(roleFormsSlice.actions.getProfiles())
        }
    } catch (error) {
        yield* call(catchProfileError, error)
    } finally {
        yield* put(roleFormsSlice.actions.setIsLoading(false))
    }
}

function* sendBeneficials() {
    const beneficials = yield* select(roleFormSelector.selectBeneficialOwnerAnketa)
    try {
        if (beneficials?.length) {
            const result = yield* all(
                beneficials.map((beneficial) => api.lib.storeBeneficialOwnerService(beneficial)),
            )
            const data = result?.filter(({ data }) => Boolean(data?.item))
            const error = result?.filter(({ error }) => Boolean(error))
            if (error?.length) {
                throw error.map(({ error }) => error?.error?.message ?? String(error)).join()
            }

            if (data?.length) {
                yield* put(
                    roleFormsSlice.actions.setBeneficiariesList(data.map(({ data }) => data.item)),
                )
            } else {
                throw new Error('no beneficial uuid has received')
            }
        }
    } catch (error) {
        yield* put(roleFormsSlice.actions.setError(error as string))
    } finally {
        yield* put(roleFormsSlice.actions.setIsLoading(false))
    }
}

function* getBeneficiaries({
    payload,
}: ReturnType<typeof roleFormsSlice.actions.getBeneficiaries>) {
    try {
        if (payload?.length) {
            const result = yield* all(
                payload.map((beneficialUuid) => api.lib.getBeneficialOwnerService(beneficialUuid)),
            )
            const data = result?.filter(({ data }) => Boolean(data?.item))
            const error = result?.filter(({ error }) => Boolean(error))
            if (error?.length) {
                throw error.map(({ error }) => error?.error?.message ?? String(error)).join()
            }
            if (data?.length) {
                yield* put(
                    roleFormsSlice.actions.updateBeneficiaryAnketa(
                        data.map(({ data }) => data.item),
                    ),
                )
            }
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'не удалось получить сведения по бенефициарным владельцам',
        })
    } finally {
        yield* put(roleFormsSlice.actions.setIsLoading(false))
    }
}

function* checkAgentUuid(profileId: string, profileType: ProfileType, agentUuid?: string) {
    const mainStatus = yield* select(roleFormSelector.selectMainStatus)
    if (afterSendingStatuses.includes(mainStatus)) {
        return
    }
    const agentUuidFromProfile = yield* select(roleFormSelector.selectAgentUuidFromProfile)
    let patchData
    if (agentUuid && !agentUuidFromProfile) {
        patchData =
            profileType === ProfileType.PRSN
                ? { agent_uuids: [agentUuid] }
                : { agent_uuid: agentUuid }
    } else if (!agentUuid && agentUuidFromProfile) {
        patchData = profileType === ProfileType.PRSN ? { agent_uuids: [''] } : { agent_uuid: '' }
    }
    if (patchData) {
        yield* call(
            api.lib.patchProfileService,
            getProfileRequestType([profileType]),
            profileId,
            patchData,
        )
    }
}

function* changeStatusAnketa({
    payload,
}: ReturnType<typeof roleFormsSlice.actions.changeStatusAnketa>) {
    try {
        yield* put(roleFormsSlice.actions.setIsLoading(true))
        const user_uuid = yield* select(authSelector.selectUserUid)
        const isNeedChange: any = [ProfileStatus.NEW, ProfileStatus.DRAFT, ProfileStatus.REWORK]

        if (user_uuid) {
            if (
                payload.type &&
                (isNeedChange.includes(payload.user_status) || !payload.user_status)
            ) {
                yield* call(checkAgentUuid, user_uuid, payload.type, payload.agent_uuid)
                const { error } = yield* call(api.lib.changeAnketaStatusService, {
                    user_uuid,
                    profile_status: ProfileStatus.OPERATOR_APPROVAL,
                    approval_datetime: dayjs().unix(),
                    profile_type: payload.type,
                })

                if (error) {
                    throw error
                }

                yield* put(roleFormsSlice.actions.getProfiles())
            }

            if (
                payload.agent_uuid &&
                (isNeedChange.includes(payload.agent_status) || !payload.agent_status)
            ) {
                const { error } = yield* call(api.lib.changeAnketaStatusService, {
                    user_uuid: payload.agent_uuid,
                    profile_status: ProfileStatus.OPERATOR_APPROVAL,
                    approval_datetime: dayjs().unix(),
                    profile_type: ProfileType.AGNT,
                })

                if (error) {
                    throw error
                }

                yield* put(roleFormsSlice.actions.getAgentProfileByUserId(user_uuid))
            }

            yield* put(profileDetailsSlice.actions.getApprovalHistory())

            if (payload.agreements) {
                yield* put(sendAgreements({ data: payload.agreements }))
            }

            payload?.callback?.()
        }
    } catch (error) {
        yield* put(roleFormsSlice.actions.setError(error as string))
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при отправке анкеты на согласование',
        })
    } finally {
        yield* put(roleFormsSlice.actions.setIsLoading(false))
    }
}

function* getProfiles() {
    try {
        const uid = yield* select(authSelector.selectUserUid)
        if (uid) {
            const { error, data } = yield* call(api.lib.getFullUser, uid)
            if (error || data?.error) {
                throw error ?? data?.error
            }
            if (data?.item) {
                yield* put(roleFormsSlice.actions.setProfile(data.item))
            }
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получении данных об анкетах',
        })
        yield* put(roleFormsSlice.actions.setError(String(error)))
    } finally {
        yield* put(roleFormsSlice.actions.setIsLoading(false))
    }
}

function* getApprovalHistoryAgent() {
    try {
        const profileAgent = yield* select(roleFormSelector.selectAgentAnketa)
        if (profileAgent?.uuid) {
            const { data, error } = yield api.lib.getStatusesProfileService(profileAgent.uuid)
            if (error) {
                throw new Error(error)
            }
            yield* put(roleFormsSlice.actions.setApprovalHistoryAgent(data.items ?? []))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка при получении истории согласовании анкеты представителя',
        })
        yield* put(roleFormsSlice.actions.setError(String(error)))
    } finally {
        yield* put(roleFormsSlice.actions.setIsLoading(false))
    }
}

function* putBusinessmanProfile({
    payload,
}: ReturnType<typeof roleFormsSlice.actions.putBusinessmanProfile>) {
    try {
        yield* put(roleFormsSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.putBusinessmanProfile, payload)
        if (error || data?.error) {
            throw error || data?.error
        }
        yield* put(roleFormsSlice.actions.getProfiles())
        openMessage({
            type: 'success',
            message: 'Анкета успешно обновлена',
        })
    } catch (error) {
        yield* call(catchProfileError, error)
    } finally {
        yield* put(roleFormsSlice.actions.setIsLoading(false))
    }
}

function* putIndividualProfile({
    payload,
}: ReturnType<typeof roleFormsSlice.actions.putIndividualProfile>) {
    try {
        yield* put(roleFormsSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.putIndividualProfile, payload)
        if (error || data?.error) {
            throw error || data?.error
        }
        yield* put(roleFormsSlice.actions.getProfiles())
        openMessage({
            type: 'success',
            message: 'Анкета успешно обновлена',
        })
    } catch (error) {
        yield* call(catchProfileError, error)
    } finally {
        yield* put(roleFormsSlice.actions.setIsLoading(false))
    }
}

function* putLegalEntityProfile({
    payload,
}: ReturnType<typeof roleFormsSlice.actions.putLegalEntityProfile>) {
    try {
        yield* put(roleFormsSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.putLegalEntityProfile, payload)
        if (error || data?.error) {
            throw error || data?.error
        }
        yield* put(roleFormsSlice.actions.getProfiles())
        openMessage({
            type: 'success',
            message: 'Анкета успешно обновлена',
        })
    } catch (error) {
        yield* call(catchProfileError, error)
    } finally {
        yield* put(roleFormsSlice.actions.setIsLoading(false))
    }
}

function* putAgentProfile({ payload }: ReturnType<typeof roleFormsSlice.actions.putAgentProfile>) {
    try {
        yield* put(roleFormsSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(api.lib.putAgentProfile, payload)
        if (error || data?.error) {
            throw error || data?.error
        }
        yield* put(roleFormsSlice.actions.getProfiles())
        openMessage({
            type: 'success',
            message: 'Анкета успешно обновлена',
        })
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при обновлении анкеты',
        })
        yield* put(roleFormsSlice.actions.setError(String(error)))
    } finally {
        yield* put(roleFormsSlice.actions.setIsLoading(false))
    }
}

function* getAgentProfileByUserId({
    payload,
}: ReturnType<typeof roleFormsSlice.actions.getAgentProfileByUserId>) {
    try {
        yield* put(roleFormsSlice.actions.setIsLoading(true))
        const { error, data } = yield* call(
            api.lib.getProfileAgentAgentsByCreatorIdService,
            payload,
        )

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data) {
            yield* put(roleFormsSlice.actions.updateAgentAnketa(data.items[0]))
        }
    } catch (error) {
        /*openMessage({
            type: 'error',
            message: 'Возникли ошибки при получении данных по агенту',
        })*/
        yield* put(roleFormsSlice.actions.setError(String(error)))
    } finally {
        yield* put(roleFormsSlice.actions.setIsLoading(false))
    }
}

export function* roleFormsSagas(): Generator<StrictEffect> {
    yield* takeLatest(roleFormsSlice.actions.sendIndividualAnketa.type, sendIndividualAnketa)
    yield* takeLatest(roleFormsSlice.actions.sendAgentAnketa.type, sendAgentAnketa)
    yield* takeLatest(roleFormsSlice.actions.sendLegalEntityAnketa.type, sendLegalEntityAnketa)
    yield* takeLatest(roleFormsSlice.actions.sendBusinessmanAnketa.type, sendBusinessmanAnketa)
    yield* takeLatest(roleFormsSlice.actions.sendBeneficiaryAnketa.type, sendBeneficials)
    yield* takeLatest(roleFormsSlice.actions.getBeneficiaries.type, getBeneficiaries)
    yield* takeLatest(roleFormsSlice.actions.changeStatusAnketa.type, changeStatusAnketa)
    yield* takeLatest(roleFormsSlice.actions.getProfiles.type, getProfiles)
    yield* takeLatest(roleFormsSlice.actions.getApprovalHistoryAgent.type, getApprovalHistoryAgent)
    yield* takeLatest(roleFormsSlice.actions.putAgentProfile.type, putAgentProfile)
    yield* takeLatest(roleFormsSlice.actions.putBusinessmanProfile.type, putBusinessmanProfile)
    yield* takeLatest(roleFormsSlice.actions.putIndividualProfile.type, putIndividualProfile)
    yield* takeLatest(roleFormsSlice.actions.putLegalEntityProfile.type, putLegalEntityProfile)
    yield* takeLatest(roleFormsSlice.actions.getAgentProfileByUserId.type, getAgentProfileByUserId)
}
