import { createAction, createSlice } from '@reduxjs/toolkit'

export interface ICreatePasswordState {
    error: string
    isAuth: boolean
    isLoading: boolean
    isRegistrationSuccessful: boolean
}

export interface ISetPassword {
    password: string
}

const initialState = {
    isAuth: false,
    isLoading: true,
    isRegistrationSuccessful: false,
} as ICreatePasswordState

const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        getCreatePasswordState(state) {
            return state
        },
        updateCreatePassword(state, action) {
            const createPasswordData = action.payload
            return { ...state, ...createPasswordData }
        },
        setCreatePasswordError(state, action) {
            state.error = action.payload
        },
    },
})

export const { updateCreatePassword, setCreatePasswordError, getCreatePasswordState } =
    registerSlice.actions

export const setPassword = createAction<ISetPassword>('register/setPassword')

export default registerSlice.reducer
