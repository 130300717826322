import { createSelector } from 'reselect'

import {
    ProfileStatus,
    ProfileType,
    agentBackToFront,
    businessmanBackToFront,
    individualBackToFront,
    legalEntityBackToFront,
} from '@dltru/dfa-models'
import { calcMainProfileStatus } from '@dltru/dfa-ui'

import { getUserFullNameByType } from '@utils/profile'

import IAppState from '../states'

const selectBeneficialOwnerAnketa = (state: IAppState) => state.roleForms.beneficialAnketa
const selectIndividualAnketa = (state: IAppState) => state.roleForms.individualAnketa
const selectIndividualFormValue = createSelector(
    selectIndividualAnketa,
    selectBeneficialOwnerAnketa,
    (anketaFromBackend, beneficialOwners) =>
        anketaFromBackend
            ? individualBackToFront(anketaFromBackend, beneficialOwners ?? undefined)
            : undefined,
)
const selectAgentAnketa = (state: IAppState) => state.roleForms.agentAnketa
const selectAgentFormValue = createSelector(selectAgentAnketa, (anketaFromBackend) =>
    anketaFromBackend ? agentBackToFront(anketaFromBackend) : undefined,
)

const selectBeneficialOwners = (state: IAppState) => state.roleForms.beneficiaries
const selectLegalEntityAnketa = (state: IAppState) => state.roleForms.legalEntityAnketa
const selectLegalEntityFormValue = createSelector(
    selectLegalEntityAnketa,
    selectBeneficialOwnerAnketa,
    (anketaFromBackend, beneficialOwners) =>
        anketaFromBackend
            ? legalEntityBackToFront(anketaFromBackend, beneficialOwners ?? undefined)
            : undefined,
)
const selectBusinessmanAnketa = (state: IAppState) => state.roleForms.businessmanAnketa
const selectBusinessmanFormValue = createSelector(
    selectBusinessmanAnketa,
    selectBeneficialOwnerAnketa,
    (anketaFromBackend, beneficialOwners) =>
        anketaFromBackend
            ? businessmanBackToFront(anketaFromBackend, beneficialOwners ?? undefined)
            : undefined,
)
const selectProfile = createSelector(
    selectBusinessmanAnketa,
    selectIndividualAnketa,
    selectLegalEntityAnketa,
    (anketaBusinessman, anketaIndividual, anketaLegalEntity) =>
        anketaBusinessman || anketaIndividual || anketaLegalEntity,
)

const selectAgentUuidFromProfile = createSelector(selectProfile, (profile) => {
    if (profile && 'agent_uuid' in profile) {
        return profile.agent_uuid
    }
    if (profile && 'agent_uuids' in profile) {
        return profile.agent_uuids?.[0]
    }
    return undefined
})

const selectMainStatus = createSelector(
    selectProfile,
    selectAgentAnketa,
    (profile, profileAgent) => {
        if (!profile?.profile_status) {
            return null
        }
        return calcMainProfileStatus(profile.profile_status, profileAgent?.application_status)
    },
)

const selectProfileType = createSelector(
    selectBusinessmanAnketa,
    selectIndividualAnketa,
    selectLegalEntityAnketa,
    selectMainStatus,
    (anketaBusinessman, anketaIndividual, anketaLegalEntity, mainStatus) => {
        if (mainStatus === ProfileStatus.REJECTED || mainStatus === ProfileStatus.ARCHIVED) {
            return undefined
        }
        if (anketaBusinessman) {
            return ProfileType.BUSN
        }

        if (anketaIndividual) {
            return ProfileType.PRSN
        }

        if (anketaLegalEntity) {
            return ProfileType.LEGL
        }
    },
)
const userFullName = createSelector(selectProfileType, selectProfile, (type, profile) =>
    profile && profile ? getUserFullNameByType(type, profile) : null,
)
const selectApprovalHistoryAgent = (state: IAppState) => state.roleForms.approvalHistoryAgent

export const roleFormSelector = {
    selectIndividualAnketa,
    selectIndividualFormValue,
    selectAgentAnketa,
    selectAgentFormValue,
    selectLegalEntityAnketa,
    selectLegalEntityFormValue,
    selectBusinessmanAnketa,
    selectBusinessmanFormValue,
    selectBeneficialOwnerAnketa,
    selectBeneficialOwners,
    selectProfile,
    userFullName,
    selectProfileType,
    selectApprovalHistoryAgent,
    selectMainStatus,
    selectAgentUuidFromProfile,
}
