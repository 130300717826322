/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
    AgentProfileChangeModel,
    BeneficialOwner,
    BusinessmanProfileChangeModel,
    IAgentProfile,
    IApprovalHistoryItem,
    IBusinessmanProfile,
    IFullUser,
    IIndividualProfile,
    ILegalEntityProfile,
    IndividualProfileChangeModel,
    LegalEntityProfileChangeModel,
    ProfileStatus,
    ProfileType,
    TAgreementData,
} from '@dltru/dfa-models'

export interface InitialStateModel {
    isLoading: boolean
    error: string | null
    individualAnketa: IIndividualProfile | null
    agentAnketa: IAgentProfile | null
    legalEntityAnketa: ILegalEntityProfile | null
    businessmanAnketa: IBusinessmanProfile | null
    beneficialAnketa: BeneficialOwner[] | null
    beneficiaries: string[] | null
    approvalHistoryAgent: IApprovalHistoryItem[]
}

const initialState: InitialStateModel = {
    isLoading: false,
    error: null,
    individualAnketa: null,
    agentAnketa: null,
    legalEntityAnketa: null,
    businessmanAnketa: null,
    beneficialAnketa: null,
    beneficiaries: null,
    approvalHistoryAgent: [],
}

export namespace RoleFormsNS {
    export type UpdateIndividualAnketa = PayloadAction<IIndividualProfile>
    export type UpdateAgentAnketa = PayloadAction<IAgentProfile>
    export type UpdateLegalEntityAnketa = PayloadAction<ILegalEntityProfile>
    export type UpdateBusinessmanAnketa = PayloadAction<IBusinessmanProfile>
    export type UpdateBeneficialOwner = PayloadAction<BeneficialOwner[]>
    export type SetError = PayloadAction<string | undefined>
    export type SetIsLoading = PayloadAction<boolean>
    export type GetBeneficiariesByUuids = PayloadAction<string[]>
    export type ChangeStatusAnketa = PayloadAction<{
        type?: ProfileType
        agent_uuid?: string
        user_status?: ProfileStatus
        agent_status?: ProfileStatus
        agreements?: Partial<TAgreementData>
        callback?: () => void
    }>
}

export const roleFormsSlice = createSlice({
    name: 'roleForms',
    initialState,
    reducers: {
        setError(state, action: RoleFormsNS.SetError) {
            state.error = action.payload ?? null
        },
        setIsLoading(state, action: RoleFormsNS.SetIsLoading) {
            state.isLoading = action.payload
        },
        setBeneficiariesList(state, action: RoleFormsNS.GetBeneficiariesByUuids) {
            state.beneficiaries = action.payload
        },
        sendIndividualAnketa() {},
        sendAgentAnketa() {},
        sendLegalEntityAnketa() {},
        sendBeneficiaryAnketa() {},
        sendBusinessmanAnketa() {},
        dropIndividualAnketa(state) {
            state.individualAnketa = null
        },
        dropAgentAnketa(state) {
            state.agentAnketa = null
        },
        dropLegalEntityAnketa(state) {
            state.legalEntityAnketa = null
        },
        clear(state) {
            state.isLoading = false
            state.error = null
            state.individualAnketa = null
            state.agentAnketa = null
            state.legalEntityAnketa = null
            state.businessmanAnketa = null
            state.beneficialAnketa = null
            state.beneficiaries = null
            state.approvalHistoryAgent = []
        },
        clearOldProfile(state) {
            if (state.legalEntityAnketa?.profile_status !== ProfileStatus.DRAFT) {
                state.legalEntityAnketa = null
                state.beneficialAnketa = null
            }
            if (state.businessmanAnketa?.profile_status !== ProfileStatus.DRAFT) {
                state.businessmanAnketa = null
                state.beneficialAnketa = null
            }
            if (state.individualAnketa?.profile_status !== ProfileStatus.DRAFT) {
                state.individualAnketa = null
                state.beneficialAnketa = null
            }
            if (
                state.agentAnketa?.application_status &&
                state.agentAnketa?.application_status !== ProfileStatus.DRAFT
            ) {
                state.agentAnketa = null
                state.approvalHistoryAgent = []
            }
        },
        updateAgentAnketa(state, action: RoleFormsNS.UpdateAgentAnketa) {
            state.agentAnketa = action.payload
        },
        updateIndividualAnketa(state, action: RoleFormsNS.UpdateIndividualAnketa) {
            state.individualAnketa = action.payload
        },
        updateLegalEntityAnketa(state, action: RoleFormsNS.UpdateLegalEntityAnketa) {
            state.legalEntityAnketa = action.payload
        },
        updateBusinessmanAnketa(state, action: RoleFormsNS.UpdateBusinessmanAnketa) {
            state.businessmanAnketa = action.payload
        },
        setProfile(state, action: PayloadAction<IFullUser>) {
            if (action.payload.profile_data?.legal_entity) {
                state.legalEntityAnketa = action.payload.profile_data.legal_entity
                state.individualAnketa = null
                state.businessmanAnketa = null
            }
            if (action.payload.profile_data?.individual) {
                state.individualAnketa = action.payload.profile_data.individual
                state.legalEntityAnketa = null
                state.businessmanAnketa = null
            }
            if (action.payload.profile_data?.businessman) {
                state.businessmanAnketa = action.payload.profile_data.businessman
                state.individualAnketa = null
                state.legalEntityAnketa = null
            }
        },
        updateBeneficiaryAnketa(state, action: RoleFormsNS.UpdateBeneficialOwner) {
            state.beneficialAnketa = action.payload
        },
        getBeneficiaries(_, action: RoleFormsNS.GetBeneficiariesByUuids) {},
        changeStatusAnketa(_, action: RoleFormsNS.ChangeStatusAnketa) {},
        getProfiles() {},
        getApprovalHistoryAgent() {},
        setApprovalHistoryAgent(state, action: PayloadAction<IApprovalHistoryItem[]>) {
            state.approvalHistoryAgent = action.payload
        },
        putBusinessmanProfile(_, action: PayloadAction<BusinessmanProfileChangeModel>) {},
        putIndividualProfile(_, action: PayloadAction<IndividualProfileChangeModel>) {},
        putLegalEntityProfile(_, action: PayloadAction<LegalEntityProfileChangeModel>) {},
        putAgentProfile(_, action: PayloadAction<AgentProfileChangeModel>) {},
        getAgentProfileByUserId(_, action: PayloadAction<string>) {},
    },
})

export default roleFormsSlice.reducer
