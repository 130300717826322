import { openMessage } from '@dltru/dfa-ui'
import { sha256 } from 'js-sha256'
import { StrictEffect } from 'redux-saga/effects'
import { put, takeLatest } from 'typed-redux-saga'

import { ISetPassword, setPassword, updateCreatePassword } from '@store/register'

import api from '@services/api'

function* handleCreatePassword({ payload }: ReturnType<typeof setPassword>) {
    const { password } = payload as ISetPassword
    try {
        const token = localStorage.getItem('passwordJWT')
        if (!token) {
            throw new Error('lost token')
        }

        const { data, error } = yield api.lib.createPasswordService(
            sha256(password).toString(),
            token,
        )

        if (error) {
            throw new Error(error)
        }

        if (!data) {
            throw new Error()
        }

        localStorage.removeItem('passwordJWT')
        yield* put(updateCreatePassword({ isRegistrationSuccessful: true, isLoading: false }))
    } catch (error: any) {
        openMessage({
            type: 'error',
            message: 'Что-то пошло не так',
        })
        yield* put(updateCreatePassword({ error: error.message, isLoading: false }))
    }
}

export function* watchCreatePassword(): Generator<StrictEffect> {
    yield* takeLatest(setPassword.type, handleCreatePassword)
}
